import "simplebar/dist/simplebar.css";

import { lazy } from "solid-js";
import { render } from "solid-js/web";
import { Router, Routes, Route } from "@solidjs/router";

const HomeScreen = lazy(() => import("./ts/screens/HomeScreen"));
const CategoryScreen = lazy(() => import("./ts/screens/CategoryScreen"));
const ProductDetailScreen = lazy(() => import("./ts/screens/ProductDetailScreen"));

function Routing() {
  return (
    <Routes>
      <Route path="/category/:slug" component={CategoryScreen} />
      <Route path="/product/:id" component={ProductDetailScreen} />
      <Route path="/" component={HomeScreen} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <Routing />
    </Router>
  );
}

render(() => <App />, document.getElementById("app"));
